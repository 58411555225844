import moment from "moment";

class Announcement {
    constructor() {
        this.id = -1
        this.content = ""
		this.created_at = ""
		this.owner = ""
		this.publish_time = new moment()
        this.title = ""
        this.shown = 0
    }

    static isDataValid(announcement) {
    	if(
            announcement.hasOwnProperty("title") && announcement.hasOwnProperty("content") &&
            announcement.hasOwnProperty("publish_time")
	    ) {
    		return announcement.title !== "" && announcement.content !== "" &&
                announcement.publish_time !== ""
	    }
    	return false
    }
}

export default Announcement;
