import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
    AppBar,
    Toolbar, Paper
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight,
    DeleteOutline,
    Close
} from '@material-ui/icons';
import { BarLoader } from 'react-spinners'

import SurveyResultDisplay from "./SurveyResultDisplay"
import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Question from "../../../model/Question"
import Dispatch from "../../../constant/dispatch";
import PieChart from "../../reusable/Chart/PieChart";
import BarChart from "../../reusable/Chart/BarChart";
import SurveyModel from "../../../model/Survey";

const styles = theme => ({
	appBar: {
		position: 'relative',
	},
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ViewSurveyDialog extends React.Component {

    onClose() {
        this.props.closeDialog()
    }

    render() {
        const { classes, isOpen, currentSurvey, isSurveyResultDataLoaded } = this.props;

        return (
            <Dialog
                open={isOpen}
                maxWidth="md"
                fullScreen={true}
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => this.onClose()} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {"Results for Survey \"" + currentSurvey.title + "\""}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{marginTop: 14}}>
                    {
                        isSurveyResultDataLoaded ? <Grid container spacing={4}>
	                        <Grid item xs={12}>
		                        <Typography variant="h5" color="textPrimary" className={classes.textField}>
			                        Survey Results
		                        </Typography>
	                        </Grid>
	                        {
		                        currentSurvey.question_json
                                    .filter(question => question.type !== "GUIDE")
                                    .map((question, index) => <Grid item xs={6} lg={4} key={index}>
			                        <SurveyResultDisplay
                                        index={index}
				                        question={question}
				                        answers={currentSurvey.answers.map(answer => answer[index])} />
		                        </Grid>)
	                        }
                        </Grid> : <Grid
	                        container
	                        spacing={0}
	                        direction="column"
	                        alignItems="center"
	                        justify="center"
	                        style={{ minHeight: '80vh' }}>
	                        <Grid item xs={3} style={{ flexBasis: '0%' }}>
		                        <BarLoader
			                        color="#98181a"
			                        margin="5px" />
	                        </Grid>
	                        <Grid item xs={3} style={{ flexBasis: '0%' }}>
		                        <Typography color="textSecondary">
			                        Getting Survey Results...
		                        </Typography>
	                        </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => this.onClose()}
                        className={classes.button}>
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = function(storage) {
    return {
        isOpen: storage.appState.isViewSurveyResultDialogOpen,
        currentSurvey: storage.survey.currentSurvey,
	    isSurveyResultDataLoaded: storage.survey.isSurveyResultDataLoaded
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUrl: url => dispatch(push(url)),
        closeDialog: () => dispatch({
            type: Dispatch.CLOSE_VIEW_SURVEY_RESULT_DIALOG
        })
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(ViewSurveyDialog);
