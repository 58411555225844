import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	TextField,
	Typography,
	Slide,
	Grid,
	Divider,
	MenuItem,
	Button,
	List,
	ListItem,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardTimePicker
} from '@material-ui/pickers';
import {
	Add,
	KeyboardArrowRight
} from '@material-ui/icons';
import { DropzoneDialog } from 'material-ui-dropzone'

import RichText from "../../reusable/RichText/"
import UploadImage from "../../reusable/UploadImage"
import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Dispatch from "../../../constant/dispatch";
import {SnackBarVariant} from "../../reusable/SnackBar";
import BoothModel from "../../../model/Booth";

const styles = theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	menu: {
		width: 200,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ViewBoothDialog extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.props.currentBooth,
			isError: false
		}
	}
	
	handleSaveData() {
		if(BoothModel.isDataValid(this.state)) {
			this.props.saveCurrentBooth(this.props.roleId)
		} else {
			this.setState({isError: true})
			this.props.enqueueSnackBar({
				message: "Data is not complete",
				type: SnackBarVariant.ERROR,
			})
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!prevProps.isOpen && this.props.isOpen) {
			this.setState({
				...this.props.currentBooth
			})
		}
	}
	
	onClose() {
		this.props.closeDialog()
	}
	
	render() {
		const { classes, isOpen, currentBooth } = this.props;
		
		return (
			<Dialog
				open={isOpen}
				maxWidth="md"
				fullWidth={true}
				TransitionComponent={Transition}>
				<DialogTitle onClose={() => this.onClose()}>
					Booth Details
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						<Grid item xs={7}>
							<TextField
								label="Booth Name"
								className={classes.textField}
								value={this.state.name}
								onChange={evt => this.setState({name: evt.target.value})}
								onBlur={evt => this.props.editCurrentBooth("name", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								error={this.state.isError && !this.state.name}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						{/*<Grid item xs={7}>*/}
							{/*<TextField*/}
								{/*label="Booth Owner"*/}
								{/*className={classes.textField}*/}
								{/*value={this.state.owner}*/}
								{/*onChange={evt => this.setState({owner: evt.target.value})}*/}
								{/*onBlur={evt => this.props.editCurrentBooth("owner", evt.target.value)}*/}
								{/*placeholder=""*/}
								{/*fullWidth*/}
								{/*margin="normal"*/}
								{/*InputLabelProps={{*/}
									{/*shrink: true,*/}
								{/*}} />*/}
						{/*</Grid>*/}
						{/*<Grid item xs={7} className={classes.textField}>*/}
							{/*<UploadImage title="Booth Picture" limit={1} />*/}
						{/*</Grid>*/}
						<Grid item xs={7}>
							<TextField
								label="Booth Image URL"
								className={classes.textField}
								value={this.state.image}
								onChange={evt => this.setState({image: evt.target.value})}
								onBlur={evt => this.props.editCurrentBooth("image", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs={11}>
							<RichText
								style={{margin: "16px 8px 8px 8px"}}
								label="Booth Description"
								value={this.state.description}
								onBlur={value => this.props.editCurrentBooth("description", value)} />
							{/*<TextField*/}
								{/*id="description"*/}
								{/*label="Booth Description"*/}
								{/*multiline*/}
								{/*fullWidth*/}
								{/*rows="6"*/}
								{/*value={this.state.description}*/}
								{/*onChange={evt => this.setState({description: evt.target.value})}*/}
								{/*onBlur={evt => this.props.editCurrentBooth("description", evt.target.value)}*/}
								{/*className={classes.textField}*/}
								{/*margin="normal"*/}
								{/*variant="outlined"*/}
								{/*error={this.state.isError && !this.state.description}*/}
								{/*InputLabelProps={{*/}
									{/*shrink: true,*/}
								{/*}} />*/}
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs={8}>
							<TextField
								label="PIC Name"
								className={classes.textField}
								value={this.state.pic_name}
								onChange={evt => this.setState({pic_name: evt.target.value})}
								onBlur={evt => this.props.editCurrentBooth("pic_name", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						<Grid item xs={5}>
							<TextField
								label="PIC Email"
								className={classes.textField}
								value={this.state.pic_email}
								onChange={evt => this.setState({pic_email: evt.target.value})}
								onBlur={evt => this.props.editCurrentBooth("pic_email", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						<Grid item xs={5}>
							<TextField
								label="PIC Phone"
								className={classes.textField}
								value={this.state.pic_phone}
								onChange={evt => this.setState({pic_phone: evt.target.value})}
								onBlur={evt => this.props.editCurrentBooth("pic_phone", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						<Grid item xs={7}>
							<TextField
								label="Category"
								className={classes.textField}
								value={this.state.category}
								onChange={evt => this.setState({category: evt.target.value})}
								onBlur={evt => this.props.editCurrentBooth("category", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								error={this.state.isError && !this.state.category}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						<Grid item xs={3}>
							<TextField
								label="Zone"
								className={classes.textField}
								value={this.state.zone}
								onChange={evt => this.setState({zone: evt.target.value})}
								onBlur={evt => this.props.editCurrentBooth("zone", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								error={this.state.isError && !this.state.zone}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.handleSaveData()}
						className={classes.button}>
						SUBMIT
					</Button>
					<Button
						variant="text"
						color="primary"
						onClick={() => this.onClose()}
						className={classes.button}>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = function(storage) {
	return {
		isOpen: storage.appState.isViewBoothDialogOpen,
		currentBooth: storage.booth.currentBooth,
		
		roleId: storage.eventManager.currentEvent.role_id
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		editCurrentBooth: (key, value) => dispatch({
			type: Dispatch.EDIT_CURRENT_BOOTH_VALUE,
			payload: {
				key: key,
				value: value
			}
		}),
		saveCurrentBooth: roleId => dispatch({
			type: Dispatch.ON_SAVE_CURRENT_BOOTH_REQUEST,
			payload: roleId
		}),
		closeDialog: () => dispatch({
			type: Dispatch.CLOSE_VIEW_BOOTH_DIALOG
		}),
		enqueueSnackBar: snackBar => dispatch({
			type: Dispatch.ENQUEUE_SNACKBAR,
			payload: snackBar
		})
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
)(ViewBoothDialog);