import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack'

import {
	Grid,
	Paper,
	Typography,
	Checkbox,
	Button,
	CssBaseline,
	CardMedia,
	Avatar,
	FormHelperText,
	FormControlLabel,
	TextField
} from "@material-ui/core";

import {push} from "connected-react-router";
import Dispatch from "../../constant/dispatch";
import SessionModel from "../../model/Session";
import UserAuth from "../../util/UserAuth";
import storage from "../../storage/storage";

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(1)
	},
	media: {
		height: 100,
		paddingTop: '56.25%', // 16:9
	},
	submit: {
		marginTop: theme.spacing(3)
	}
});

class Login extends Component {

	constructor(props) {
		super(props)

		this.state = {
			email: "demo@komunestudio.com",
			password: "",
			rememberMe: false,
			emailIsInvalid: false,
			passwordIsInvalid: false
		}

		if(localStorage.jwtToken) {
			console.log("YOU HAVE A TOKEN MY FRIEND")
			storage.dispatch({type: Dispatch.ON_LOGIN_FROM_SESSION_REQUEST})
		}
	}

	// componentDidMount() {
	// 	if(localStorage.jwtToken) {
	// 		if (!isEmpty(this.props.pages.currentPage)) {
	// 			this.props.history.push(this.props.pages.currentPage.route);
	// 		} else {
	// 			this.props.history.push("/dashboard");
	// 		}
	// 	}
	// }
	//
	// componentWillReceiveProps(nextProps) {
	// 	if (nextProps.users.isAuthenticated) {
	// 		if (!isEmpty(this.props.pages.currentPage)) {
	// 			this.props.history.push(this.props.pages.currentPage.route);
	// 		} else {
	// 			this.props.history.push("/dashboard");
	// 		}
	// 	}
	//
	// 	if (nextProps.errors) {
	// 		this.setState({ errors: nextProps.errors });
	// 	}
	// }

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.isUserLoggedIn && !prevProps.isUserLoggedIn) {
			if(localStorage.tempUrl) {
				this.props.setUrl(localStorage.tempUrl)
			} else {
				this.props.setUrl("/event-manager")
			}
		}
	}

	login() {
		this.props.login(this.state)
		// this.props.setUrl("/event-manager")
	}

	render() {
		const { classes } = this.props

		return <Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justify="center"
			className={classes.root}
			style={{ minHeight: '100vh' }}>
			<Grid item xs={4} style={{ flexBasis: '0%' }}>
				<Paper elevation={6} className={classes.paper}>
					<Typography variant="h5">
						demo dashboard
					</Typography>
					<Grid container justify="center" spacing={0} style={{marginTop: "16px"}}>
						<Grid item xs={12}>
							<TextField
								label="Email"
								value={this.state.email}
								onChange={evt => this.setState({email: evt.target.value})}
								placeholder=""
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Password"
								type="password"
								value={this.state.password}
								onChange={evt => this.setState({password: evt.target.value})}
								placeholder=""
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						{/*<Grid item xs={12}>*/}
							{/*<FormControlLabel*/}
								{/*control={*/}
									{/*<Checkbox*/}
										{/*checked={this.state.rememberMe}*/}
										{/*onChange={() => this.setState({rememberMe: !this.state.rememberMe})}*/}
										{/*color="primary" />*/}
								{/*}*/}
								{/*label="Remember Me"*/}
							{/*/>*/}
						{/*</Grid>*/}
						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => this.login()}
								className={this.props.classes.submit} >
								Login
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	}
}

const mapStateToProps = function(storage) {
	return {
		currentUser: storage.user.currentUser,
		isUserLoggedIn: storage.user.isUserLoggedIn
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		login: data => dispatch({
			type: Dispatch.ON_LOGIN_REQUEST,
			payload: data
		}),
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
	withSnackbar
)(Login)
