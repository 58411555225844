import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	TextField,
	Typography,
	Slide,
	Grid,
	Divider,
	MenuItem,
	Button,
	List,
	ListItem,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardTimePicker
} from '@material-ui/pickers';
import {
	Add,
	KeyboardArrowRight
} from '@material-ui/icons';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const styles = theme => ({
	editor: {
		padding: "0px 14px 18.5px 14px",
		height: "20em",
		overflow: "scroll"
	},
	toolBar: {
		margin: "16px 8px",
		border: "solid 1px rgba(0, 0, 0, 0.23)"
	},
	wrapper: {
		color: "rgba(0, 0, 0, 0.87)",
		border: "solid 1px",
		borderColor: "rgba(0, 0, 0, 0.23)",
		borderRadius: "4px",
		transition: "padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
		
		"&:hover": {
			borderColor: "rgba(0, 0, 0, 0.87)"
		},
		
		"&:focus-within": {
			border: "solid 2px",
			borderColor: theme.palette.primary.main,
		},
	},
	label: {
		position: "relative",
		top: "10px",
		left: "10px",
		backgroundColor: "#FFFFFF",
		padding: "0px 4px",
	},
	labelColorFocused: {
		color: theme.palette.primary.main,
	}
});

class RichText extends React.Component {

	constructor(props) {
		super(props)
		const html = this.props.value
		const contentBlock = htmlToDraft(html)
		let editorState = EditorState.createEmpty()
		if(contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
			editorState = EditorState.createWithContent(contentState)
		}
		this.state = {
			editor: editorState,
			isFocused: false
		}
	}
	
	render() {
		let { classes, label, style } = this.props
		
		return (
			<div style={style}>
				<Typography
					className={classes.label + " " + (this.state.isFocused && classes.labelColorFocused)}
					color="textSecondary"
					variant="caption">
					{label}
				</Typography>
				<Editor
					// toolbarOnFocus
					editorState={this.state.editor}
					toolbarClassName={classes.toolBar}
					wrapperClassName={classes.wrapper}
					editorClassName={classes.editor}
					onFocus={() => this.setState({isFocused: true})}
					onEditorStateChange={editorState => this.setState({editor: editorState})}
					onBlur={() => {
						this.setState({isFocused: false})
						this.props.onBlur(draftToHtml(convertToRaw(this.state.editor.getCurrentContent())))
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
)(RichText);