import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	TextField,
	Typography,
	Slide,
	Grid,
	Divider,
	MenuItem,
	Button,
	List,
	ListItem,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import {
	Close
} from '@material-ui/icons';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { DialogTitle, DialogContent, DialogActions } from "../reusable/Dialog"
// import dialogStyles from "../../styles/dialog"
import { eventTypes, pricingPlan } from "./config/eventDataDisplayConfig"

import Dispatch from "../../constant/dispatch";

const styles = theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	menu: {
		width: 200,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ViewEventDialog extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			name: ""
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!prevProps.isOpen && this.props.isOpen) {
			this.setState({name: this.props.currentEvent.name})
		}
	}
	
	onClose() {
		this.props.closeDialog()
	}
	
	render() {
		const { classes, isOpen, currentEvent } = this.props;
		
		return (
			<Dialog
				open={isOpen}
				maxWidth="lg"
				fullWidth={true}
				TransitionComponent={Transition}>
				<DialogTitle onClose={() => this.onClose()}>
					Create New Event
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						<Grid item xs={7}>
							<TextField
								label="Event Name"
								className={classes.textField}
								value={this.state.name}
								onChange={evt => this.setState({name: evt.target.value})}
								onBlur={evt => this.props.editCurrentEvent("name", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
						<Grid item xs={3}>
							<TextField
								select
								fullWidth
								label="Event Type"
								className={classes.textField}
								value={currentEvent.type}
								onChange={evt => this.props.editCurrentEvent("type", evt.target.value)}
								SelectProps={{
									MenuProps: {
										className: classes.menu,
									},
								}}
								margin="normal">
								{eventTypes.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs="auto">
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									format="DD/MM/YYYY"
									margin="normal"
									value={currentEvent.start_time}
									onChange={date => this.props.editCurrentEvent("start_time", date)}
									className={classes.textField}
									label="Event Start Date"
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs="auto">
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									format="DD/MM/YYYY"
									margin="normal"
									value={currentEvent.end_time}
									onChange={date => this.props.editCurrentEvent("end_time", date)}
									className={classes.textField}
									label="Event End Date"
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						{
							pricingPlan.map((plan, index) => <Grid item xs={3} key={"pricingPlan"+index}>
								<Card>
									<CardHeader title={<Typography variant="subtitle1">{plan.title}</Typography>}/>
									<CardContent>
										<List>
											<Divider/>
											{
												plan.content.map((content, i) => <div key={content+i}>
													<ListItem>
														<Typography variant="caption">{content}</Typography>
													</ListItem>
													<Divider/>
												</div>)
											}
										</List>
									</CardContent>
									<CardActions>
										<Button
											fullWidth
											variant={plan.value === currentEvent.pricingPlan ? "contained" : "outlined"}
											color="primary"
											onClick={() => this.props.editCurrentEvent("pricingPlan", plan.value)}
											className={classes.button}>
											CHOOSE PLAN
										</Button>
									</CardActions>
								</Card>
							</Grid>)
						}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.props.saveCurrentEvent()}
						className={classes.button}>
						SUBMIT
					</Button>
					<Button
						variant="text"
						color="primary"
						onClick={() => this.onClose()}
						className={classes.button}>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = function(storage) {
	return {
		isOpen: storage.appState.isViewEventDialogOpen,
		currentEvent: storage.eventManager.currentEvent
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		editCurrentEvent: (key, value) => dispatch({
			type: Dispatch.EDIT_CURRENT_EVENT_VALUE,
			payload: {
				key: key,
				value: value
			}
		}),
		saveCurrentEvent: () => dispatch({
			type: Dispatch.SAVE_CURRENT_EVENT
		}),
		closeDialog: () => dispatch({
			type: Dispatch.CLOSE_VIEW_EVENT_DIALOG
		})
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
)(ViewEventDialog);