import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles/index";
import {connect} from "react-redux";
import {compose} from "recompose";
import {Chart} from "react-google-charts";
import { BarLoader } from 'react-spinners'

const styles = theme => ({
});

class BarChart extends Component {

	render() {
		let { data,columns } = this.props

		// if(!columns){
		// 	columns = data[0]
		// 	data = data.splice(0,1)
		// }

		return <Chart
			width={'100%'}
			height={'200px'}
			chartType="Bar"
			loader={<div>
				<BarLoader
					color="#98181a"
					margin="5px" />
			</div>}
			// columns={columns}
			data={data}
			options={{
				title: null,
				sliceVisibilityThreshold: 0.05,
				pieStartAngle: 180,
				slices: [
					{
						color: "#98181a"
					},
				],
				legend: {
					position: "none"
				},
			}}
			rootProps={{ 'data-testid': '2' }}
		/>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(BarChart)

