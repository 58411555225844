import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles/index";
import {connect} from "react-redux";
import {compose} from "recompose";
import { BarLoader } from 'react-spinners'
import {Chart} from "react-google-charts";

const styles = theme => ({
	loader: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
});

class PieChart extends Component {

	render() {
		const { data } = this.props

		return <Chart
			width={'100%'}
			height={'200px'}
			chartType="PieChart"
			loader={<div>
				<BarLoader
					color="#98181a"
					margin="5px" />
			</div>}
			data={data}
			options={{
				title: null,
				sliceVisibilityThreshold: 0.03,
				pieStartAngle: 180,
				slices: [
					{
						color: "#F47A1F"
					},
					{
						color: "#FDBB2F"
					},
					{
						color: "#377B2B"
					},
					{
						color: "#7AC142"
					},
					{
						color: "#007CC3"
					},
					{
						color: "#00529B"
					}
				],
				legend: {
					position: "right",
					alignment: "center",
					textStyle: {
						color: "233238",
						fontSize: 14
					}
				},
			}}
			rootProps={{ 'data-testid': '2' }}
		/>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(PieChart)
