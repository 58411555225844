import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { push } from "connected-react-router"
import queryString from 'query-string'

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack'
import {
	Typography,
	CssBaseline,
	Avatar,
	AppBar,
	Toolbar,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem
} from "@material-ui/core";
import { IconContext } from "react-icons";
import {
	FaUsers,
	FaMedal,
	FaNewspaper,
	FaBook
} from "react-icons/fa"

import {
	Home as HomeIcon,
	Map as MapIcon,
	Store,
	Timer,
	QuestionAnswer,
	Settings,
	ArrowBack,
	Menu as MenuIcon, Announcement, Poll
} from '@material-ui/icons';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { SnackBarVariant, generateSnackBar } from "../reusable/SnackBar"

import Home from "./Home/Home"
import EventDetail from "./EventDetail/EventDetail"
import Map from "./Map/Map"
import Booth from "./Booth/Booth"
import Book from "./Books/Book"
import Session from "./Session/Session"
import News from "./News/News"
import Survey from "./Survey/Survey"
import Announcements from "./Announcement/Announcement"

import Dispatch from "../../constant/dispatch";
import UserAuth from "../../util/UserAuth";
import Polling from "./Polling/Polling";
import Certificate from "./Certificate/Certificate";

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
		minWidth: "200px"
	},
})(props => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const drawerWidth = 240;
const styles = theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	title: {
		flexGrow: 1
	},
	avatar: {
		margin: 10,
		color: '#fff',
		backgroundColor: theme.palette.primary.dark,
	},
	toolbar: theme.mixins.toolbar,
	progress: {
		margin: theme.spacing(2),
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	icon : {
		height:'100%'
	}
});

class Dashboard extends Component {

	constructor(props) {
		super(props)

		this.state = {
			anchor: null
		}

		if(!props.isUserLoggedIn) {
			UserAuth.handleUserNotLoggedIn(props)
			return
		}
		const queryParams = queryString.parse(this.props.location.search)
		if(queryParams.e) {
			if(props.isEventDataLoaded) {
				this.props.setActiveEvent(parseInt(queryParams.e))
			} else {
				this.props.getEventById(queryParams.e)
			}
		} else {
			// generateSnackBar(props, "An error occurred", SnackBarVariant.ERROR)
			props.setUrl("/event-manager")
		}
	}

	render() {
		const { classes, currentUser, currentEvent } = this.props

		return <div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<Typography className={classes.title} variant="h6" noWrap>
						{currentEvent.name}
					</Typography>
					<Typography variant="body2" noWrap>
						{currentUser.email}
					</Typography>
					<Avatar
						className={classes.avatar}
						onClick={e => this.setState({anchor: e.currentTarget})}>
						{currentUser.email.substring(0,2).toUpperCase()}
					</Avatar>
					<StyledMenu
						id="menu"
						anchorEl={this.state.anchor}
						keepMounted
						open={!!this.state.anchor}
						onClose={() => this.setState({anchor: null})}
					>
						<MenuItem onClick={() => {
							this.props.logOut()
							this.props.setUrl("/login")
						}}>Log Out</MenuItem>
					</StyledMenu>
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant="permanent"
				classes={{
					paper: classes.drawerPaper,
				}}>
				<div className={classes.toolbar} />
				<List>
					<ListItem button key={"Back"} onClick={() => {
						this.props.returnToEventManagerPage()
						this.props.setUrl("/event-manager")
					}}>
						<ListItemIcon><ArrowBack /></ListItemIcon>
						<ListItemText primary={"Back to Event List"} />
					</ListItem>
				</List>
				<Divider />
				<List>
					<ListItem
						button
						key={"Home"}
						selected={this.props.location.pathname === `${this.props.match.path}/`}
						onClick={() => this.props.setUrl(`${this.props.match.path}/`)} >
						<ListItemIcon><HomeIcon /></ListItemIcon>
						<ListItemText primary={"Home"} />
					</ListItem>
					<ListItem
						button
						key={"Menu"}
						selected={this.props.location.pathname === `${this.props.match.path}/detail`}
						onClick={() => this.props.setUrl(`${this.props.match.path}/detail`)} >
						<ListItemIcon><MenuIcon /></ListItemIcon>
						<ListItemText primary={"Event Detail"} />
					</ListItem>
					{/*<ListItem button key={"Map"} onClick={() => this.props.setUrl(`${this.props.match.path}/map`)}>*/}
					{/*<ListItemIcon><MapIcon /></ListItemIcon>*/}
					{/*<ListItemText primary={"Map"} />*/}
					{/*</ListItem>*/}
					{/*<ListItem*/}
					{/*button*/}
					{/*key={"Store"}*/}
					{/*selected={this.props.location.pathname === `${this.props.match.path}/booth`}*/}
					{/*onClick={() => this.props.setUrl(`${this.props.match.path}/booth`)} >*/}
					{/*<ListItemIcon><Store /></ListItemIcon>*/}
					{/*<ListItemText primary={"Booth"} />*/}
					{/*</ListItem>*/}
					<ListItem
						button
						key={"Timer"}
						selected={this.props.location.pathname === `${this.props.match.path}/session`}
						onClick={() => this.props.setUrl(`${this.props.match.path}/session`)} >
						<ListItemIcon><Timer /></ListItemIcon>
						<ListItemText primary={"Session"} />
					</ListItem>
					<ListItem
						button
						key={"QuestionAnswer"}
						selected={this.props.location.pathname === `${this.props.match.path}/survey`}
						onClick={() => this.props.setUrl(`${this.props.match.path}/survey`)} >
						<ListItemIcon><QuestionAnswer /></ListItemIcon>
						<ListItemText primary={"Survey"} />
					</ListItem>
					<ListItem
						button
						key={"Book"}
						selected={this.props.location.pathname === `${this.props.match.path}/book`}
						onClick={() => this.props.setUrl(`${this.props.match.path}/book`)} >
						<ListItemIcon><IconContext.Provider value={{className: "icon", size:24 }}>
							<div>
								<FaBook />
							</div>
						</IconContext.Provider></ListItemIcon>
						<ListItemText primary={"Book"} />
					</ListItem>
                    <ListItem
                        button
                        key={"News"}
                        selected={this.props.location.pathname === `${this.props.match.path}/news`}
                        onClick={() => this.props.setUrl(`${this.props.match.path}/news`)} >
                        <ListItemIcon><IconContext.Provider value={{className: "icon", size:24 }}>
                            <div>
                                <FaNewspaper />
                            </div>
                        </IconContext.Provider></ListItemIcon>
                        <ListItemText primary={"News"} />
                    </ListItem>
					{/*
					<ListItem
						button
						key={"Announcement"}
						selected={this.props.location.pathname === `${this.props.match.path}/announcement`}
						onClick={() => this.props.setUrl(`${this.props.match.path}/announcement`)} >
						<ListItemIcon><Announcement /></ListItemIcon>
						<ListItemText primary={"Announcement"} />
					</ListItem>*/}

					{/*<ListItem*/}
					{/*button*/}
					{/*key={"Certificate"}*/}
					{/*selected={this.props.location.pathname === `${this.props.match.path}/certificate`}*/}
					{/*onClick={() => this.props.setUrl(`${this.props.match.path}/certificate`)} >*/}
					{/*<ListItemIcon><IconContext.Provider value={{className: "icon", size:24 }}>*/}
					{/*<div>*/}
					{/*<FaMedal />*/}
					{/*</div>*/}
					{/*</IconContext.Provider></ListItemIcon>*/}
					{/*<ListItemText primary={"Certificate"} />*/}
					{/*</ListItem>*/}
					{/*<ListItem*/}
					{/*button*/}
					{/*key={"Polling"}*/}
					{/*selected={this.props.location.pathname === `${this.props.match.path}/polling`}*/}
					{/*onClick={() => this.props.setUrl(`${this.props.match.path}/polling`)} >*/}
					{/*<ListItemIcon><IconContext.Provider value={{className: "icon", size:24 }}>*/}
					{/*<div>*/}
					{/*<FaUsers />*/}
					{/*</div>*/}
					{/*</IconContext.Provider></ListItemIcon>*/}
					{/*<ListItemText primary={"Pop Quiz and Polling"} />*/}
					{/*</ListItem>*/}
				</List>
				{/*<Divider />*/}
				{/*<List>*/}
				{/*<ListItem button key={"Settings"}>*/}
				{/*<ListItemIcon><Settings /></ListItemIcon>*/}
				{/*<ListItemText primary={"Settings"} />*/}
				{/*</ListItem>*/}
				{/*</List>*/}
			</Drawer>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Router>
					<Switch location={this.props.location}>
						<Route path={`${this.props.match.path}/detail`} component={EventDetail} />
						<Route path={`${this.props.match.path}/map`} component={Map} />
						{/*<Route path={`${this.props.match.path}/booth`} component={Booth} />*/}
						<Route path={`${this.props.match.path}/session`} component={Session} />
						<Route path={`${this.props.match.path}/survey`} component={Survey} />
						<Route path={`${this.props.match.path}/polling`} component={Polling} />
						<Route path={`${this.props.match.path}/announcement`} component={Announcements} />
						<Route path={`${this.props.match.path}/book`} component={Book} />
						<Route path={`${this.props.match.path}/certificate`} component={Certificate} />
						<Route path={`${this.props.match.path}/news`} component={News} />
						<Route path={`${this.props.match.path}/`} component={Home} />
					</Switch>
				</Router>
			</main>
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
		currentUser: storage.user.currentUser,
		isUserLoggedIn: storage.user.isUserLoggedIn,
		isEventDataLoaded: storage.eventManager.isEventDataLoaded,
		currentEvent: storage.eventManager.currentEvent,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		getEventById: id => dispatch({
			type: Dispatch.ON_GET_EVENT_BY_ID_REQUEST,
			payload: id
		}),
		setActiveEvent: id => dispatch({
			type: Dispatch.SET_CURRENT_EVENT,
			payload: id
		}),
		returnToEventManagerPage: () => dispatch({
			type: Dispatch.RETURN_TO_EVENT_MANAGER_PAGE
		}),
		logOut: () => dispatch({
			type: Dispatch.LOG_OUT
		}),
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
	withSnackbar
)(Dashboard)
