import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { push } from "connected-react-router"

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack'
import {
	Typography,
	CssBaseline,
	Avatar,
	AppBar,
	Toolbar,
	Grid,
	Button,
	Divider,
	Container,
	Menu,
	MenuItem
} from "@material-ui/core";
import {
	LibraryBooks,
	SupervisorAccount,
	ExitToApp
} from '@material-ui/icons';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import DataTable from "../reusable/DataTable"

import noData from "../../image/no-data.svg"
import columns from "./config/eventTableConfig"
import Dispatch from "../../constant/dispatch";
import UserAuth from "../../util/UserAuth"

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
		minWidth: "200px"
	},
})(props => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const drawerWidth = 240;
const styles = theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: theme.palette.primary.main
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(10),
		// paddingLeft: theme.spacing(24),
		// paddingRight: theme.spacing(24),
	},
	title: {
		flexGrow: 1
	},
	avatar: {
		margin: 10,
		color: '#fff',
		backgroundColor: theme.palette.primary.dark,
	},
	toolbar: theme.mixins.toolbar,
	button: {
		margin: theme.spacing(1),
	},
});

class EventManager extends Component {

	constructor(props) {
		super(props)

		this.state = {
			anchor: null
		}

		if(!props.isUserLoggedIn) {
			UserAuth.handleUserNotLoggedIn(props)
			return
		}
		// if(!this.props.isEventDataLoaded) {
			this.props.getEvents()
		// }
	}

	render() {
		const { classes, currentUser, events } = this.props

		return <div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<Typography className={classes.title} variant="h6" noWrap>
						demo dashboard
					</Typography>
					<Typography variant="body2" noWrap>
						{currentUser.email}
					</Typography>
					<Avatar
						className={classes.avatar}
						onClick={e => this.setState({anchor: e.currentTarget})}>
						{currentUser.email.substring(0,2).toUpperCase()}
					</Avatar>
					<StyledMenu
						id="menu"
						anchorEl={this.state.anchor}
						keepMounted
						open={!!this.state.anchor}
						onClose={() => this.setState({anchor: null})}
					>
						<MenuItem onClick={() => {
							this.props.logOut()
							this.props.setUrl("/login")
						}}>Log Out</MenuItem>
					</StyledMenu>
				</Toolbar>
			</AppBar>
			<Container className={classes.content} maxWidth="md">
				<div className={classes.toolbar} />
				{
					events.length === 0 ? <Grid
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justify="center"
						style={{ minHeight: '80vh' }}>
						<Grid item xs={3}>
							<img src={noData} height={100} style={{marginBottom: "10px"}}/>
						</Grid>
						<Grid item xs={3}>
							<Typography color="textSecondary">
								You have no events yet.
							</Typography>
						</Grid>
						<Grid item xs={"auto"}>
							<Button color="primary" className={classes.button} onClick={() => this.props.openViewEventDialog()}>
								CREATE EVENT
							</Button>
						</Grid>
					</Grid> : <DataTable
						title="List of Events"
						selectableRows="none"
						filter={true}
						data={events}
						columns={columns}
						// createNew={() => this.props.openViewEventDialog()}
						onRowClick={event => {
							this.props.setUrl("/dashboard?e=" + event.id)
						}} />
				}
			</Container>
		</div>
	}
}

const mapStateToProps = function(storage) {
	return {
		currentUser: storage.user.currentUser,
		events: storage.eventManager.events,
		isUserLoggedIn: storage.user.isUserLoggedIn,
		isEventDataLoaded: storage.eventManager.isEventDataLoaded
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		getEvents: () => dispatch({
			type: Dispatch.ON_GET_ALL_EVENTS_REQUEST
		}),
		openViewEventDialog: () => dispatch({
			type: Dispatch.OPEN_VIEW_EVENT_DIALOG
		}),
		setActiveEvent: event => dispatch({
			type: Dispatch.SET_CURRENT_EVENT,
			payload: event
		}),
		logOut: () => dispatch({
			type: Dispatch.LOG_OUT
		}),
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
	withSnackbar
)(EventManager)
