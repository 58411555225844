import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    TextField,
    Typography,
    Slide,
    Grid,
    Divider,
    MenuItem,
    Button,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import {
    Add,
    KeyboardArrowRight
} from '@material-ui/icons';
import moment from "moment";
import MomentUtils from '@date-io/moment';

import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Question from "../../../model/Question"
import Dispatch from "../../../constant/dispatch";
import { sessionTypes } from "./config/sessionDataDisplayConfig";
import {SnackBarVariant} from "../../reusable/SnackBar";
import SessionModel from "../../../model/Session";
import UploadImage from "../../reusable/UploadImage";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ViewSessionDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...this.props.currentSession,
            isError: false
        }
    }

    handleSaveData() {
        if(SessionModel.isDataValid(this.state)) {
            this.props.saveCurrentSession(this.props.roleId)
        } else {
            this.setState({isError: true})
            this.props.enqueueSnackBar({
                message: "Data is not complete",
                type: SnackBarVariant.ERROR,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.isOpen && this.props.isOpen) {
            this.setState({
                ...this.props.currentSession
            })
        }
    }

    onClose() {
        this.props.closeDialog()
    }

    render() {
        const { classes, isOpen, currentSession } = this.props;

        return (
            <Dialog
                open={isOpen}
                maxWidth="md"
                fullWidth={true}
                TransitionComponent={Transition}>
                <DialogTitle onClose={() => this.onClose()}>
                    Session Details
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={7}>
                            <TextField
                                label="Session Name"
                                className={classes.textField}
                                value={this.state.session_name}
                                onChange={evt => this.setState({session_name: evt.target.value})}
                                onBlur={evt => this.props.editCurrentSession("session_name", evt.target.value)}
                                placeholder=""
                                fullWidth
                                margin="normal"
                                error={this.state.isError && !this.state.session_name}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                label="Speaker Name"
                                className={classes.textField}
                                value={this.state.session_speaker}
                                onChange={evt => this.setState({session_speaker: evt.target.value})}
                                onBlur={evt => this.props.editCurrentSession("session_speaker", evt.target.value)}
                                placeholder=""
                                fullWidth
                                margin="normal"
                                error={this.state.isError && !this.state.session_speaker}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs="auto">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    format="DD/MM/YYYY"
                                    margin="normal"
                                    value={currentSession.start_time}
                                    onChange={date => {
                                        this.props.editCurrentSession("start_time", new moment(date).set({
                                            hour:   currentSession.start_time.get('hour'),
                                            minute: currentSession.start_time.get('minute'),
                                            second: currentSession.start_time.get('second')
                                        }))
                                        this.props.editCurrentSession("end_time", new moment(date).set({
                                            hour:   currentSession.end_time.get('hour'),
                                            minute: currentSession.end_time.get('minute'),
                                            second: currentSession.end_time.get('second')
                                        }))
                                    }}
                                    className={classes.textField}
                                    label="Session Date"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs="auto">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    label="Time Start"
                                    value={currentSession.start_time}
                                    onChange={date => this.props.editCurrentSession("start_time", date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs="auto">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    label="Time End"
                                    value={currentSession.end_time}
                                    onChange={date => this.props.editCurrentSession("end_time", date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        {/*<Grid item xs={3}>*/}
                        {/*<TextField*/}
                        {/*select*/}
                        {/*fullWidth*/}
                        {/*label="Session Type"*/}
                        {/*className={classes.textField}*/}
                        {/*value={this.state.type}*/}
                        {/*onChange={evt => this.props.editCurrentSession("type", evt.target.value)}*/}
                        {/*SelectProps={{*/}
                        {/*MenuProps: {*/}
                        {/*className: classes.menu,*/}
                        {/*},*/}
                        {/*}}*/}
                        {/*margin="normal">*/}
                        {/*{sessionTypes.map(option => (*/}
                        {/*<MenuItem key={option.value} value={option.value}>*/}
                        {/*{option.label}*/}
                        {/*</MenuItem>*/}
                        {/*))}*/}
                        {/*</TextField>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs="3">*/}
                        {/*<TextField*/}
                        {/*type="number"*/}
                        {/*label="Quota"*/}
                        {/*className={classes.textField}*/}
                        {/*value={this.state.quota}*/}
                        {/*onChange={evt => this.setState({quota: evt.target.value})}*/}
                        {/*onBlur={evt => this.props.editCurrentSession("quota", evt.target.value)}*/}
                        {/*placeholder=""*/}
                        {/*fullWidth*/}
                        {/*margin="normal"*/}
                        {/*helperText="Input 0 for session without quota limit"*/}
                        {/*InputLabelProps={{*/}
                        {/*shrink: true,*/}
                        {/*}} />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs="4">*/}
                        {/*<TextField*/}
                        {/*type="number"*/}
                        {/*label="Entry Price"*/}
                        {/*className={classes.textField}*/}
                        {/*value={this.state.price}*/}
                        {/*onChange={evt => this.setState({price: evt.target.value})}*/}
                        {/*onBlur={evt => this.props.editCurrentSession("price", evt.target.value)}*/}
                        {/*placeholder=""*/}
                        {/*fullWidth*/}
                        {/*margin="normal"*/}
                        {/*helperText="Input 0 for session without entry price"*/}
                        {/*InputLabelProps={{*/}
                        {/*shrink: true,*/}
                        {/*}} />*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={11}>
                            <TextField
                                id="description"
                                label="Session Description"
                                multiline
                                fullWidth
                                rows="6"
                                value={this.state.description}
                                onChange={evt => this.setState({description: evt.target.value})}
                                onBlur={evt => this.props.editCurrentSession("description", evt.target.value)}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                error={this.state.isError && !this.state.description}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={11} className={classes.textField}>
                        <UploadImage
                            event={this.props.currentSession}
                            image={this.props.currentSession.image_url}
                            onImageUploaded={(event,image)=>{this.props.uploadImage(event,"image",image)}}
                            title="Session Image" limit={1} />
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={11}>
                            <TextField
                                id="venue"
                                label="Session Location"
                                multiline
                                fullWidth
                                rows="3"
                                value={this.state.zone}
                                onChange={evt => this.setState({zone: evt.target.value})}
                                onBlur={evt => this.props.editCurrentSession("zone", evt.target.value)}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                error={this.state.isError && !this.state.zone}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <TextField
                                id="price"
                                label="Price (0 for Free Session)"
                                value={this.state.price}
                                onChange={evt => this.setState({price: evt.target.value})}
                                onBlur={evt => this.props.editCurrentSession("price", evt.target.value)}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                error={this.state.isError}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="quota"
                                label="Quota (0 for No Quota)"
                                value={this.state.quota}
                                onChange={evt => this.setState({quota: evt.target.value})}
                                onBlur={evt => this.props.editCurrentSession("quota", evt.target.value)}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                error={this.state.isError}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleSaveData()}
                        className={classes.button}>
                        SUBMIT
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => this.onClose()}
                        className={classes.button}>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = function(storage) {
    return {
        isOpen: storage.appState.isViewSessionDialogOpen,
        currentSession: storage.session.currentSession,
        roleId: storage.eventManager.currentEvent.role_id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUrl: url => dispatch(push(url)),
        editCurrentSession: (key, value) => dispatch({
            type: Dispatch.EDIT_CURRENT_SESSION_VALUE,
            payload: {
                key: key,
                value: value
            }
        }),
        saveCurrentSession: roleId => dispatch({
            type: Dispatch.ON_SAVE_CURRENT_SESSION_REQUEST,
            payload: roleId
        }),
        closeDialog: () => dispatch({
            type: Dispatch.CLOSE_VIEW_SESSION_DIALOG
        }),
        enqueueSnackBar: snackBar => dispatch({
            type: Dispatch.ENQUEUE_SNACKBAR,
            payload: snackBar
        }),
        uploadImage: (session, fieldKey, image) => dispatch({
            type: Dispatch.ON_UPDATE_SESSION_IMAGE_REQUEST,
            payload: {session, fieldKey, image}
        })

    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
)(ViewSessionDialog);
