import React from "react"
import moment from "moment"
import {
    Tooltip,
    IconButton, Button, Typography
} from "@material-ui/core";
import {
    VerticalAlignBottom,
    Edit,
    Group
} from '@material-ui/icons';
import storage from "../../../../storage/storage"
import Dispatch from "../../../../constant/dispatch"

export default [
    {
        name: "id",
        label: "Registration ID",
        options: {
            display: true,
            filter: false,
            sort: false,
            viewColumns: false,
        }
    },
    {
        name: "full_name",
        label: "Full Name",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "email",
        label: "Email",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "id",
        label: "Time Remaining",
        options: {
            filter: false,
            sort: true,
            customBodyRender: value => {

                let registration = storage.getState().session.currentSession.registrations.find(registration=> registration.id === value)
                if(registration.status !== "WAITING"){
                    return "-"
                }
                let timeLeft = 24-Math.abs(Math.round(moment.duration(moment.utc(registration.modified_at).diff(new Date())).asHours()))
                return <Typography style={{color:timeLeft>0?"#1DAB24":"#F80000"}}>
                    {timeLeft+ "h"}
                </Typography>

            }

        }
    },
    {
        name: "scanned",
        label: "Scanned",
        options: {
            filter: false,
            sort: true,
            customBodyRender: value => {
                return value ? "Yes" : "No"
            }
        }
    },
    {
        name: "total_price",
        label: "Price to Pay",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "amount",
        label: "Ticket Count",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "id",
        label: "Options",
        options: {
            filter: false,
            sort: false,
            customBodyRender: value => {
                let registration = storage.getState().session.currentSession.registrations.find(registration=> registration.id === value)

                return <>
                    {
                        registration.status === "PENDING" || registration.status === "WAITING"?<><Tooltip title="Click only if the user has paid">
                            <Button variant="outlined" color="primary"
                                    onClick={() => {
                                        storage.dispatch({
                                            type: Dispatch.ON_REGISTRATION_CHANGE_PAYMENT_STATUS_REQUEST,
                                            payload: {
                                                registrationInfo : registration,
                                                status : "ACCEPTED"
                                            }
                                        })
                                    }}>
                                ACCEPT PAYMENT
                            </Button>
                        </Tooltip><Tooltip title="Click here to remove user from queue">
                            <Button variant="outlined" color="primary"
                                    onClick={() => {
                                        storage.dispatch({
                                            type: Dispatch.ON_REGISTRATION_CHANGE_PAYMENT_STATUS_REQUEST,
                                            payload: {
                                                registrationInfo : registration,
                                                status : "REJECTED"
                                            }
                                        })
                                    }}>
                                DECLINE
                            </Button>
                        </Tooltip></>:null
                    }
                </>

            }
        }
    },
]
