class Booth {
    constructor() {
        this.id = -1
        this.name = "" //ada //WAJIB
        this.owner = "" // bakal ada //WAJIB
        this.icon = null // bakal ada
        this.image = ""
        this.description = "" // ada //WAJIB
	    this.pic_name = "" // bakal ada
	    this.pic_phone = "" // bakal ada
	    this.pic_email = "" // bakal ada
        this.category = "" // fakultasnya apa, udah ada, sementara string input //WAJIB
        this.zone = "" // tempatnya dimana, contoh R315 //WAJIB
		this.enabled = 1
	    
	    this.qr_codes = []
    }
    
    static isDataValid(booth) {
    	if(
    		booth.hasOwnProperty("name") && booth.hasOwnProperty("description") &&
		    booth.hasOwnProperty("category") && booth.hasOwnProperty("zone")
	    ) {
    		return booth.name !== "" && booth.description !== "" &&
			    booth.category !== "" && booth.zone !== ""
	    }
    	return false
    }
}

export default Booth;
