import UserAction from "../model/UserAction"
import User from "../model/User"
import Dispatch from "../constant/dispatch"

const defaultState = {
	currentUser: new User(),
	
	isUserLoggedIn: false
}


export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.ON_LOGIN_REQUEST) {
		UserAction.login(action.payload)
	} else if(action.type === Dispatch.ON_LOGIN_FROM_SESSION_REQUEST) {
		UserAction.loginWithToken()
	} else if(
		action.type === Dispatch.ON_LOGIN_RESPONSE_SUCCESS ||
		action.type === Dispatch.ON_LOGIN_FROM_SESSION_RESPONSE_SUCCESS
	) {
		return {
			...state,
			currentUser: action.payload,
			isUserLoggedIn: true
		}
		
	} else if(action.type === Dispatch.LOG_OUT) {
		return defaultState
	}
	return state
}