import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import {
	Tooltip,
	Button,
	IconButton
} from "@material-ui/core";
import {
	Clear
} from '@material-ui/icons';

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider, withSnackbar } from 'notistack';

import LoadingOverlay from 'react-loading-overlay'
import { PulseLoader } from 'react-spinners'

import SnackBarManager from "./SnackBarManager"

import EventManager from "./eventManager/EventManager"
import Dashboard from "./dashboard/Dashboard"
import Login from "./login/Login"
import LandingPage from "./landingPage/LandingPage"


import ViewQRDialog from "./reusable/ViewQRDialog"
import ViewEventDialog from "./eventManager/ViewEventDialog"
import ViewBoothDialog from "./dashboard/Booth/ViewBoothDialog"
import ViewBookDialog from "./dashboard/Books/ViewBookDialog"
import ViewAnnouncementDialog from "./dashboard/Announcement/ViewAnnouncementDialog"
import ViewSessionDialog from "./dashboard/Session/ViewSessionDialog"
import ViewSessionRegistrationsDialog from "./dashboard/Session/ViewSessionRegistrationsDialog"
import ViewSurveyDialog from "./dashboard/Survey/ViewSurveyDialog"
import ViewQuestionDialog from "./dashboard/Survey/ViewQuestionDialog"
import ViewNewsDialog from "./dashboard/News/ViewNewsDialog"
import ViewSurveyResultDialog from "./dashboard/Survey/ViewSurveyResultDialog"

import Dispatch from "../constant/dispatch";
import storage from "../storage/storage";
import UserAuth from "../util/UserAuth"

const styles = theme => ({
});

if(localStorage.jwtToken) {
	UserAuth.handleTokenReceived(localStorage.jwtToken)
}

class App extends Component {

	render() {
		const theme = createMuiTheme({
			palette: {
				primary: {
					main: "#000",
					// main: "#98181a",
					// dark: "#cb241c"
					dark: "#202020"
				},
				secondary: {
					main: "#ffffff",
					text: "#000000"
				},
				delete: "#98181a",
				green: "#00c853"
			},
			dialog: {
				framePadding: 10
			},
			// overrides: {
			// 	MUIDataTableBodyCell: {
			// 		root: {
			// 			backgroundColor: "#FF0000"
			// 		}
			// 	},
			// 	MUIButton: {
			// 		root: {
			// 			backgroundColor: "#FFFFFF"
			// 		}
			// 	}
			// }
		});
		return <MuiThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				autoHideDuration={5000}>
				<SnackBarManager />
				<LoadingOverlay
					active={this.props.isLoading}
					styles={{overlay: {
							position: "fixed",
							height: "100%",
							width: "100%",
							top: "0px",
							left: "0px",
							display: "flex",
							textAlign: "center",
							fontSize: "1.2em",
							color: "#FFF",
							background: "rgba(0,0,0,0.7)",
							zIndex: "10000",
							webkitTransition: "opacity 500ms ease-in",
							transition: "opacity 500ms ease-in",
							opacity: "1"
						}}}
					spinner={
						<PulseLoader
							color="#98181a"
							size={15}
							margin="5px" />
					}
					classNamePrefix="loading_"
					text="Please Wait...">
					<Router>
						<Switch location={this.props.location}>
							<Route path="/login" component={Login} />
							<Route path="/dashboard" component={Dashboard} />
							<Route path="/event-manager" component={EventManager} />
							<Route path="/" component={Login} />
						</Switch>
					</Router>

					{/*Dialogs*/}
					<ViewQRDialog />
					<ViewEventDialog />
					<ViewBoothDialog />
					<ViewBookDialog />
					<ViewSessionDialog />
					<ViewSurveyDialog />
					<ViewQuestionDialog />
					<ViewSurveyResultDialog />
					<ViewAnnouncementDialog />
					<ViewSessionRegistrationsDialog />
					<ViewNewsDialog />
				</LoadingOverlay>
			</SnackbarProvider>
		</MuiThemeProvider>
	}
}

const mapStateToProps = function(storage) {
	return {
		location: storage.router.location,
		isLoading: storage.appState.isLoading
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

App.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles, { withTheme: true }),
)(App)
