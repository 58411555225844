import AnnouncementAction from "../model/AnnouncementAction"
import Dispatch from "../constant/dispatch"
import News from "../model/News";
import NewsAction from "../model/NewsAction";
import SessionAction from "../model/SessionAction";

const defaultState = {
	currentNews: new News(),
	news: [],
}

function handleLocalChanges(state, action) {
	if(action.type === Dispatch.OPEN_VIEW_NEWS_DIALOG) {
		return {
			...state,
            currentNews: typeof action.payload === "object" ?
				action.payload :
				state.news.find(news => news.id === action.payload)
		}
	} else if(action.type === Dispatch.CLOSE_VIEW_NEWS_DIALOG) {
		return {
			...state,
            currentNews: defaultState.currentNews
		}
	} else if(action.type === Dispatch.RETURN_TO_EVENT_MANAGER_PAGE) {
		return defaultState
	} else if(action.type === Dispatch.EDIT_CURRENT_NEWS_VALUE) {
        return {
            ...state,
            currentNews: {
                ...state.currentNews,
                [action.payload.key]: action.payload.value
            }
        }
    }
    else if(action.type === Dispatch.ON_UPDATE_NEWS_IMAGE_RESPONSE_SUCCESS){
        return {
            ...state,
            currentNews:{
                ...state.currentNews,
                [action.payload.fieldKey]: action.payload.location
            }
        }
    }
	return state
}

export default function reducer(state = defaultState, action) {
	if(action.type === Dispatch.ON_GET_NEWS_REQUEST) {
		NewsAction.getNews(action.payload)
	} else if(action.type === Dispatch.ON_GET_NEWS_RESPONSE_SUCCESS){
		return {
			...state,
			news: action.payload,
		}
    } else if(action.type === Dispatch.ON_SAVE_NEWS_RESPONSE_SUCCESS) {
		NewsAction.getNews(action.payload)
		/*
        return {
            ...state,
            news: state.news.find(a => a.id === action.payload.id) ?
                state.news.map(news => news.id === action.payload.id ? action.payload : news) :
                state.news.concat(action.payload)
		}*/
    } else if (action.type === Dispatch.ON_SAVE_NEWS_REQUEST) {
		let news = {...state.currentNews}
		let eventId = action.payload
		news.event_id = eventId
		news.id === -1 ? NewsAction.createNews(news) : NewsAction.updateNews(news)
	} else if (action.type === Dispatch.ON_UPDATE_NEWS_IMAGE_REQUEST) {
        NewsAction.updateNewsImage(action.payload.news, action.payload.fieldKey, action.payload.image)
	} else if (action.type === Dispatch.ON_TOGGLE_ANNOUNCEMENT_SHOWN_REQUEST) {
		let announcementId = action.payload
		let announcements = [...state.announcements]
		console.log(announcements)
		let selectedAnnouncement = announcements.find(announcement =>{
			return announcement.id === announcementId
		})
		selectedAnnouncement.shown = !selectedAnnouncement.shown
		AnnouncementAction.updateAnnouncement(selectedAnnouncement)
	}
	return handleLocalChanges(state, action)
}
