class Book {
    constructor() {
        this.id = -1

        this.title = ""
        this.author = ""
        this.category = ""
        this.publisher = ""

        this.price = 0
    }

    static isDataValid(book) {
        if(
            book.hasOwnProperty("title") && book.hasOwnProperty("author") &&
            book.hasOwnProperty("category")
        ) {
            return book.title !== "" && book.author !== "" &&
                book.category !== ""
        }
        return false
    }

}

export default Book
