import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import { withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	TextField,
	Slide,
	Grid,
	Button,
} from "@material-ui/core";

import RichText from "../../reusable/RichText/"
import { DialogTitle, DialogContent, DialogActions } from "../../reusable/Dialog"

import Dispatch from "../../../constant/dispatch";
import {SnackBarVariant} from "../../reusable/SnackBar";
import AnnouncementModel from "../../../model/Announcement";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import MomentUtils from '@date-io/moment';

const styles = theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	menu: {
		width: 200,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ViewAnnouncementDialog extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.props.currentAnnouncement,
			isError: false
		}
	}

	handleSaveData() {
		console.log(this.props.currentAnnouncement)
		if(AnnouncementModel.isDataValid(this.props.currentAnnouncement)) { //previously uses this state... but why? on this point this.state content is not same with this.props.currentAnnoucnement
			this.props.saveCurrentAnnouncement(this.props.eventId)
		} else {
			console.log("shit not valid yuh", this.state)
			this.setState({...this.state, isError: true})
			this.props.enqueueSnackBar({
				message: "Data is not complete",
				type: SnackBarVariant.ERROR,
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!prevProps.isOpen && this.props.isOpen) {
			console.log(this.props.currentAnnouncement)
			this.setState({
				...this.props.currentAnnouncement
			})
		}
	}

	onClose() {
		this.props.closeDialog()
	}

	render() {
		const { classes, isOpen, currentAnnouncement } = this.props;

		return (
			<Dialog
				open={isOpen}
				maxWidth="md"
				fullWidth={true}
				TransitionComponent={Transition}>
				<DialogTitle onClose={() => this.onClose()}>
					Announcement Details
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						<Grid item xs={7}>
							<TextField
								label="Announcement Title"
								className={classes.textField}
								value={this.state.title}
								onChange={evt => this.setState({...this.state, title: evt.target.value})}
								onBlur={evt => this.props.editCurrentAnnouncement("title", evt.target.value)}
								placeholder=""
								fullWidth
								margin="normal"
								error={this.state.isError && !this.state.name}
								InputLabelProps={{
									shrink: true,
								}} />
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs={11}>
							<RichText
								style={{margin: "16px 8px 8px 8px"}}
								label="Announcement Description"
								value={currentAnnouncement.content}
								onBlur={value => this.props.editCurrentAnnouncement("content", value)} />
						</Grid>
					</Grid>
                    <Grid container spacing={4}>
                        <Grid item xs="auto">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    format="DD/MM/YYYY"
                                    margin="normal"
                                    value={currentAnnouncement.publish_time}
                                    onChange={date => {
                                    	console.log(date)
                                    	this.props.editCurrentAnnouncement("publish_time", date)
                                    }}
                                    className={classes.textField}
                                    label="Announcement Publish Date"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs="auto">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    format="HH:mm"
                                    margin="normal"
                                    value={currentAnnouncement.publish_time}
                                    onChange={date => this.props.editCurrentAnnouncement("publish_time", date)}
                                    className={classes.textField}
                                    label="Announcement Publish Time"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.handleSaveData()}
						className={classes.button}>
						SUBMIT
					</Button>
					<Button
						variant="text"
						color="primary"
						onClick={() => this.onClose()}
						className={classes.button}>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = function(storage) {
	return {
		isOpen: storage.appState.isViewAnnouncementDialogOpen,
		currentAnnouncement: storage.announcement.currentAnnouncement,
		eventId: storage.eventManager.currentEvent.id,
		roleId: storage.eventManager.currentEvent.role_id
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url)),
		closeDialog: () => dispatch({
			type: Dispatch.CLOSE_VIEW_ANNOUNCEMENT_DIALOG
		}),
		enqueueSnackBar: snackBar => dispatch({
			type: Dispatch.ENQUEUE_SNACKBAR,
			payload: snackBar
		}),
        editCurrentAnnouncement: (key, value) => {
			dispatch({
                type: Dispatch.EDIT_CURRENT_ANNOUNCEMENT_VALUE,
                payload: {
                    key: key,
                    value: value
                }
            })
		},
        saveCurrentAnnouncement: (announcement) => dispatch({
            type: Dispatch.ON_SAVE_ANNOUNCEMENT_REQUEST,
			payload:announcement
		}),
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(styles),
)(ViewAnnouncementDialog);
