import axios from "axios"
import { push } from "connected-react-router"

import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"
import UserAuth from "../util/UserAuth"
import { SnackBarVariant } from "../component/reusable/SnackBar"

export default {
    getBooks: eventId => {
        axios.get(server + "/books")
            .then(response => {
                storage.dispatch({type: Dispatch.ON_GET_BOOKS_RESPONSE_SUCCESS, payload: response.data.map(books => {
                        return {
                            ...books,
                        }
                    })})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_GET_BOOKS_RESPONSE_FAIL})
                if(err.response === undefined)
                    storage.dispatch({
                        type: Dispatch.ENQUEUE_SNACKBAR,
                        payload: {
                            id: Math.round(Math.random()*1000),
                            message: "Failed to retrieve data",
                            type: SnackBarVariant.ERROR,
                            options: [{
                                text: "RETRY",
                                action: () => storage.dispatch({
                                    type: Dispatch.ON_GET_BOOKS_REQUEST,
                                    payload: eventId
                                })
                            }]
                        }
                    })
            })
    },
    createBook: book => {
        delete book.id
        axios.post(server + "/books", book)
            .then(response => {
                storage.dispatch({
                    type: Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_SUCCESS,
                    payload: {
                        ...book,
                        id: response.data.insert_id
                    }
                })
                storage.dispatch({type: Dispatch.CLOSE_VIEW_BOOK_DIALOG})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_FAIL})
            })
    },
    updateBook : book => {
        axios.patch(server + "/books/" + book.id, book)
            .then(response => {
                storage.dispatch({
                    type: Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_SUCCESS,
                    payload: {
                        ...book,
                        id: book.id
                    }
                })
                storage.dispatch({type: Dispatch.CLOSE_VIEW_BOOK_DIALOG})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_BOOK_RESPONSE_FAIL})
            })
    },
    deleteBook: book => {
        axios.delete(server + "/books/" + book.id)
            .then(response => {
                storage.dispatch({
                    type: Dispatch.ON_DELETE_BOOK_BY_ID_RESPONSE_SUCCESS,
                    payload: {
                        ...book,
                        id: book.id
                    }
                })
                storage.dispatch({type: Dispatch.CLOSE_VIEW_BOOK_DIALOG})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_DELETE_BOOK_BY_ID_RESPONSE_FAIL})
            })
    }
}
