export const eventTypes = [
	{
		value: 'type1',
		label: 'Seminar',
	},
	{
		value: 'type2',
		label: 'Edu-Fair',
	},
	{
		value: 'type3',
		label: 'Competition',
	}
];

export const pricingPlan = [
	{
		value: "plan1",
		title: "Paket Murah",
		content: ["Murah Banget", "Cip cip"]
	},
	{
		value: "plan2",
		title: "Paket Ekonomis",
		content: ["Mari berhemat", "Masih cip juga"]
	},
	{
		value: "plan3",
		title: "Paket Komplit",
		content: ["Dapet semua fitur", "Ini udah bagus"]
	},
	{
		value: "plan4",
		title: "Paket Hedon",
		content: ["Best offer", "90% off!"]
	}
]

export const targetAudience = [
	{
		value: 'targetAudience1',
		label: 'Senior High',
	},
	{
		value: 'targetAudience2',
		label: 'University',
	}
]