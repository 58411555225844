import moment from "moment"
import { eventTypes, pricingPlan } from "./eventDataDisplayConfig"

export default [
	{
		name: "id",
		label: "id",
		options: {
			display: "false",
			filter: false,
			sort: false,
			viewColumns: false,
		}
	},
	{
		name: "name",
		label: "Event Name",
		options: {
			filter: false,
			sort: true,
		}
	},
	// {
	// 	name: "type",
	// 	label: "Event Type",
	// 	options: {
	// 		filter: true,
	// 		sort: true,
	// 		customBodyRender: value => eventTypes.find(type => type.value === value).label
	// 	}
	// },
	{
		name: "start_time",
		label: "Start Date",
		options: {
			filter: false,
			sort: true,
			customBodyRender: value => value.format("DD/MM/YYYY")
		}
	},
	{
		name: "end_time",
		label: "End Date",
		options: {
			filter: false,
			sort: true,
			customBodyRender: value => value.format("DD/MM/YYYY")
		}
	},
	// {
	// 	name: "pricingPlan",
	// 	label: "Pricing Plan",
	// 	options: {
	// 		filter: true,
	// 		sort: true,
	// 		customBodyRender: value => pricingPlan.find(plan => plan.value === value).title
	// 	}
	// },
]