import React from "react"
import moment from "moment"
import {
	Tooltip,
	IconButton
} from "@material-ui/core";
import {
	VerticalAlignBottom,
	Edit,
	Group
} from '@material-ui/icons';
import storage from "../../../../storage/storage"
import Dispatch from "../../../../constant/dispatch"

export default [
	{
		name: "id",
		label: "id",
		options: {
			display: "false",
			filter: false,
			sort: false,
			viewColumns: false,
		}
	},
	{
		name: "session_name",
		label: "Session Name",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "session_speaker",
		label: "Speaker Name",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "start_time",
		label: "Date Held",
		options: {
			filter: true,
			sort: true,
			customBodyRender: value => value.format("DD/MM/YYYY")
		}
	},
	{
		name: "start_time",
		label: "Start Time",
		options: {
			filter: false,
			sort: true,
			customBodyRender: value => value.format("HH:mm")
		}
	},
	{
		name: "end_time",
		label: "End Time",
		options: {
			filter: false,
			sort: true,
			display: false,//ganti 24
			customBodyRender: value => value.format("HH:mm")
		}
	},
    {
        name: "price",
        label: "Price",
        options: {
            filter: false,
            sort: true,
			customBodyRender: value => <>
				{value===0?"Free":"Rp. "+ value}
			</>
        }
    },
	{
		name: "zone",
		label: "Location",
		display : "False",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "id",
		label: "Options",
		options: {
			filter: false,
			sort: false,
			customBodyRender: value => <>
				<Tooltip title="Download QR">
					<IconButton
						onClick={() => {
							storage.dispatch({
								type: Dispatch.OPEN_VIEW_QR_DIALOG,
								payload: {...storage.getState().session.sessions.find(session => session.id === value)},
								flag: "Session"
							})
						}}>
						<VerticalAlignBottom />
					</IconButton>
				</Tooltip>
                <Tooltip title="Registration Management">
                    <IconButton
                        onClick={() => storage.dispatch({
                            type: Dispatch.OPEN_VIEW_SESSION_REGISTRATION_DIALOG,
                            payload: value
                        })}>
                        <Group />
                    </IconButton>
                </Tooltip>
				<Tooltip title="Edit">
					<IconButton
						onClick={() => storage.dispatch({
							type: Dispatch.OPEN_VIEW_SESSION_DIALOG,
							payload: value
						})}>
						<Edit />
					</IconButton>
				</Tooltip>
			</>
		}
	},
]
