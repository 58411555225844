import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles/index";
import {connect} from "react-redux";
import {compose} from "recompose";
import {Chart} from "react-google-charts";
import { BarLoader } from 'react-spinners'

const styles = theme => ({
});

class BarChart extends Component {

	render() {
		let { rows,columns } = this.props

		// if(!columns){
		// 	columns = data[0]
		// 	data = data.splice(0,1)
		// }

		return <Chart
			width={'100%'}
			height={'400px'}
			chartType="Bar"
			loader={<div>
				<BarLoader
					color="#98181a"
					margin="5px" />
			</div>}
			// columns={columns}
			rows={rows}
			columns={columns}
			options={{
				bars:'horizontal',
				title: null,
				sliceVisibilityThreshold: 0.05,
				legend: {
					position: "none"
				},
				series:{
					0:{axis:'Count'},
					1:{axis:'Percentage'},
				},
                hAxis: { title: 'Age', minValue: 0, maxValue: 15 },
                vAxis: { title: 'Weight', minValue: 0, maxValue: 15 },

            }}
			rootProps={{ 'data-testid': '2' }}
		/>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(BarChart)

