import axios from "axios"
import moment from "moment"

import { dateToString } from "../util/dateUtil";
import storage from "../storage/storage"
import Dispatch from "../constant/dispatch"
import server from "../constant/server"

export default {
    getSession: eventId => {
        axios.get(server + "/events/" + eventId + "/sessions")
            .then(response => {
                console.log("session data", response)
                storage.dispatch({type: Dispatch.ON_GET_SESSIONS_RESPONSE_SUCCESS, payload: response.data.map(session => {
                        return {
                            ...session,
                            start_time: moment.utc(session.start_time),
                            end_time: moment.utc(session.end_time),
                            qr_codes: []
                        }
                    })})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_GET_SESSIONS_RESPONSE_FAIL})
            })
    },

    createSession: session => {
        delete session.id
        session.start_time = session.start_time.format()
        session.end_time = session.end_time.format()
        axios.post(server + "/sessions", session)
            .then(response => {
                console.log("session created", response)
                storage.dispatch({
                    type: Dispatch.ON_SAVE_CURRENT_SESSION_RESPONSE_SUCCESS,
                    payload: storage.getState().eventManager.currentEvent.id
                })
                storage.dispatch({type: Dispatch.CLOSE_VIEW_SESSION_DIALOG})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_SESSION_RESPONSE_FAIL})
            })
    },

    updateSession: session => {
        session.start_time = session.start_time.format()
        session.end_time = session.end_time.format()
        axios.patch(server + "/sessions/" + session.id, session)
            .then(response => {
                console.log("session updated", response)
                storage.dispatch({
                    type: Dispatch.ON_SAVE_CURRENT_SESSION_RESPONSE_SUCCESS, 
                    payload: storage.getState().eventManager.currentEvent.id
                })
                storage.dispatch({type: Dispatch.CLOSE_VIEW_SESSION_DIALOG})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_SAVE_CURRENT_SESSION_RESPONSE_FAIL})
            })
    },

    deleteSession: session => {
        axios.delete(server + "/sessions/" + session.id)
            .then(response => {
                console.log("session deleted", response)
                storage.dispatch({type: Dispatch.ON_DELETE_SESSION_BY_ID_RESPONSE_SUCCESS, payload: session})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_DELETE_SESSION_BY_ID_RESPONSE_FAIL})
            })
    },

    updateSessionImage: (params, fieldKey, image)=>{
        let formData = new FormData();
        formData.append('upload',image[0])
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post(server + `/sessions/${params.id}/IMAGE/upload`, formData, config)
            .then(response => {
                storage.dispatch({type: Dispatch.ON_UPDATE_SESSION_IMAGE_RESPONSE_SUCCESS, payload:{
                        location : response.data.location,
                        fieldKey : fieldKey+"_url"
                    }})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_UPDATE_SESSION_IMAGE_RESPONSE_FAIL})
            })
    },
    getRegistrations : session => {
        axios.get(server + "/sessions/" + session.id + "/registration")
            .then(response => {
                console.log("registrations data", response)
                storage.dispatch({type: Dispatch.ON_GET_SESSIONS_REGISTRATIONS_RESPONSE_SUCCESS, payload: response.data})
            })
            .catch(err => {
                console.log(err.response)
                storage.dispatch({type: Dispatch.ON_GET_SESSIONS_REGISTRATIONS_RESPONSE_FAIL})
            })
    },
	updateRegistrationState : (registrationInfo, status)=>{
        axios.post(server + "/sessions/" + registrationInfo.sessionId + "/registration/"+registrationInfo.userId+"/"+status)
            .then(response => {
                storage.dispatch({type: Dispatch.ON_REGISTRATION_CHANGE_PAYMENT_STATUS_RESPONSE_SUCCESS, payload:response.data})
                console.log(response)
            })
            .catch(err => {
                console.log(err)
                storage.dispatch({type: Dispatch.ON_REGISTRATION_CHANGE_PAYMENT_STATUS_RESPONSE_FAIL})
            })
	}

}
