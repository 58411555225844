import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from "recompose"

import { push } from "connected-react-router"

import { withStyles } from '@material-ui/core/styles';
import {
	Typography,
	AppBar,
	Toolbar,
} from "@material-ui/core";

import Dispatch from "../../constant/dispatch";

const styles = theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: theme.palette.secondary.main
	},
	appBarTypography: {
		color: theme.palette.secondary.text
	}
});

class Home extends Component {

	render() {
		const classes = this.props.classes

		return <AppBar position="fixed" className={classes.appBar} classes={{root: classes.appBarTypography}}>
			<Toolbar>
				<Typography className={classes.title} variant="h6" noWrap>
                    patjarmerah malang
				</Typography>
			</Toolbar>
		</AppBar>
	}
}

const mapStateToProps = function(storage) {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUrl: url => dispatch(push(url))
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles)
)(Home)
